<template>
  <div>
    <v-row justify="start" class="px-3">
      <v-btn
        class="text-capitalize primary rounded-xl white--text"
        @click="openDialog"
      >
        Add new plan
      </v-btn>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">Total Plans</v-card-title>
          <v-card-text class="text-h6">{{ plans.length || 0 }}</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            With Custom Branding
          </v-card-title>
          <v-card-text class="text-h6">{{ withCustomBranding }}</v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="text-center" flat>
          <v-card-title class="d-flex justify-center">
            Without Custom Branding
          </v-card-title>
          <v-card-text class="text-h6">{{ withoutCustomBranding }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="plans"
          :items-per-page="10"
          :search="search"
          :loading="loading"
          loading-text="fetching plans, please wait..."
        >
          <template v-slot:item.numbers="{ index }">
            <div>{{ index + 1 }}</div>
          </template>
          <template v-slot:item.amount="{ item }">
            <div>${{ item.amount }}</div>
          </template>
          <template v-slot:item.custom_branding="{ item }">
            <div class="text-capitalize">{{ item.custom_branding }}</div>
          </template>
          <template v-slot:item.minutes="{ item }">
            <div class="text-capitalize">{{ minutes(item) }}</div>
          </template>
          <template v-slot:item.plan_type="{ item }">
            <v-chip
              class="ma-2 text-capitalize"
              :color="getPlanTypeColor(item.plan_type)"
              pill
              small
            >
              {{ item.plan_type }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div>
              <v-menu offset-y>
                <template v-slot:activator="{ attrs, on }">
                  <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                </template>
                <v-list>
                  <v-list-item @click="openDialog(item)">Edit</v-list-item>
                  <v-list-item @click="deletePlan(item)">Delete</v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog max-width="800" v-model="dialog" persistent scrollable>
      <v-card elevation="0" color="white">
        <v-card-title>
          <v-toolbar class="white--text" color="primary" flat>
            {{ form_title }}
          </v-toolbar>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form lazy-validation ref="add_update_plan" class="pa-4">
            <v-row class="pt-10">
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="form.plan_type"
                  :items="[
                    { text: 'Standard', value: 'standard' },
                    { text: 'AppSumo', value: 'appsumo' },
                    { text: 'Lifetime', value: 'lifetime' },
                  ]"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  label="Plan Type"
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.name"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  placeholder="Enter plan name"
                  label="Plan Name"
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.amount"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  placeholder="Enter plan amount"
                  type="number"
                  label="Plan Amount"
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.minutes"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  placeholder="Max minutes, enter 1000000000 for unlimited"
                  label="Plan Recording Minutes"
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.accounts"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  label="Reseller License"
                  placeholder="Enter maximum number of accounts"
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  v-model="form.custom_branding"
                  :items="[
                    { text: 'Yes', value: 'yes' },
                    { text: 'No', value: 'no' },
                  ]"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  placeholder="Does this plan support custom branding"
                  label="Custom branding feature"
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.custom_domains"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  placeholder="Enter number of custom domain"
                  type="number"
                  label="Plan max number of Custom domain"
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="form.storage"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  placeholder="Max storage, or type `unlimited`"
                  label="Storage"
                  :rules="[(v) => !!v || 'This field is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="form.price_id"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  placeholder="Enter the Paddle price ID"
                  label="Monthly Price ID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="form.annual_price_id"
                  outlined
                  dense
                  background-color="secondary"
                  rounded
                  placeholder="Enter the Paddle price ID"
                  label="Yearly Price ID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            class="text-capitalize secondary rounded-xl black--text px-5"
            @click="closeDialog"
          >
            Close
          </v-btn>
          <v-btn
            v-if="editing"
            class="text-capitalize primary rounded-xl white--text px-5"
            @click="$refs.add_update_plan.validate() ? updatePlan() : null"
            :loading="loadSubmit"
          >
            Update
          </v-btn>
          <v-btn
            v-else
            class="text-capitalize primary rounded-xl white--text px-5"
            @click="$refs.add_update_plan.validate() ? createPlan() : null"
            :loading="loadSubmit"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "PlansPage",
  data() {
    return {
      search: "",
      headers: [
        { text: "#", value: "numbers", sortable: false, orderable: false },
        { text: "Name", value: "name" },
        { text: "Amount", value: "amount" },
        { text: "Accounts", value: "accounts" },
        { text: "Custom Branding", value: "custom_branding" },
        { text: "Minutes", value: "minutes" },
        { text: "Type", value: "plan_type" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          orderable: false,
        },
      ],
      plans: [],
      loading: false,
      form_title: "",
      form: {
        duration: "m",
      },
      editing: false,
      dialog: false,
      loadSubmit: false,
    };
  },
  created() {
    this.getPlans();
  },
  computed: {
    withCustomBranding() {
      return this.plans.filter((x) => x.custom_branding === "yes").length;
    },
    withoutCustomBranding() {
      return this.plans.filter((x) => x.custom_branding === "no").length;
    },
  },
  methods: {
    ...mapActions("plans", [
      "create_plan",
      "get_plans",
      "update_plan",
      "delete_plan",
    ]),
    ...mapMutations("auth", ["LOADING"]),
    async getPlans() {
      this.loading = true;
      try {
        const response = await this.get_plans();
        this.plans = response.data;
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
    async createPlan() {
      this.loadSubmit = true;
      try {
        const response = await this.create_plan(this.form);
        this.loadSubmit = false;
        this.closeDialog();
        await this.getPlans();
        this.$response.sendSuccess(response);
      } catch (e) {
        this.loadSubmit = false;
        this.$response.sendError(e);
      }
    },
    async updatePlan() {
      this.loadSubmit = true;
      try {
        const response = await this.update_plan(this.form);
        await this.getPlans();
        this.loadSubmit = false;
        this.closeDialog();
        this.$response.sendSuccess(response);
      } catch (e) {
        this.loadSubmit = false;
        this.$response.sendError(e);
      }
    },
    async deletePlan(item) {
      const confirmDelete = confirm(
        "Please note that this will delete all recordings associated with this plan"
      );
      if (confirmDelete) {
        await this.LOADING(true);
        try {
          const response = await this.delete_plan(item);
          await this.getPlans();
          await this.LOADING(false);
          this.$response.sendSuccess(response);
        } catch (e) {
          await this.LOADING(false);
          this.$response.sendError(e);
        }
      }
    },
    openDialog(item) {
      if (item.custom_branding) {
        this.editing = true;
        this.form = Object.assign({}, item);
      }
      this.form_title = this.editing ? "Update Plan" : "Create Plan";
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.editing = false;
      this.form = {
        duration: "m",
      };
    },
    minutes(item) {
      return ["Pro Basic", "Pro Premium"].includes(item.name)
        ? "Unlimited"
        : item.minutes;
    },
    getPlanTypeColor(planType) {
      switch (planType) {
        case "standard":
          return "primary";
        case "appsumo":
          return "success";
        case "lifetime":
          return "033";
        default:
          return "primary"; // Fallback color
      }
    },
  },
};
</script>

<style scoped></style>
